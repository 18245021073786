/* eslint-disable camelcase */
import React, { FC, useState, useRef, useMemo } from 'react';
import { graphql, Link } from 'gatsby';
import { PageLayout, Pagination, PageHeading, FAQ } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';
import { truncate } from 'utils';

const PageSize = 9;

const Trainers: FC = ({ data }: any) => {
  const {
    PageDescription,
    title,
    personalTrainer,
    faq,
    banner,
    seo: { metaDescription, metaTitle, shareImage },
  } = data.strapiPersonalTrainerPage;
  const [currentPage, setCurrentPage] = useState(1);
  const personalTrainerData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return personalTrainer.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  const titleref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const handleonClick = (page: any) => {
    setCurrentPage(page);
    window.scrollTo(0, titleref.current.offsetHeight);
  };
  return (
    <PageLayout
      title="Personal Trainers"
      metaTitle={metaTitle ?? title}
      description={metaDescription}
      image={shareImage?.localFile?.publicURL}
      hasContainerClass
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      faqs={faq.faqs}
    >
      <>
        <PageHeading title={title} description={PageDescription} level={1} />
        <section className="trainer-list" ref={titleref}>
          {personalTrainerData.map((trainer: any) => {
            const { id, name, description, image, slug, qualification } =
              trainer;
            const trainerImage: any = image
              ? getImage(image.localFile.childImageSharp)
              : null;
            const content = truncate(description, 140);
            return (
              <div className="trainer-section-single-item" key={id}>
                <div className="trainer-section-single-item-wrapper">
                  <div className="trainer-section-single-item-image">
                    <Link to={`/services/fitness/${slug}`}>
                      {trainerImage && (
                        <GatsbyImage
                          image={trainerImage}
                          alt={image?.alternativeText ?? (title || '')}
                        />
                      )}
                    </Link>
                  </div>
                  <div className="trainer-section-single-item-qualification">
                    {qualification}
                  </div>
                  <div className="trainer-section-single-item-detail">
                    <div className="trainer-section-single-item-detail-wrapper">
                      <h4 className="trainer-section-single-item-title">
                        <Link to={`/services/fitness/${slug}`}>{name}</Link>
                      </h4>

                      <div className="trainer-section-single-item-description">
                        <ReactMarkdown className="md-html">
                          {content}
                        </ReactMarkdown>
                      </div>
                    </div>
                    <Link to={`/services/fitness/${slug}`}>
                      <div className="trainer-section-single-item-contact">
                        <span> Book Personal Trainer </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
        <Pagination
          currentPage={currentPage}
          totalCount={personalTrainer.length}
          pageSize={PageSize}
          onPageChange={handleonClick}
        />
        {faq && (
          <section className="home-page-faq-section">
            <PageHeading title={faq.title} subtitle={faq.tagline} />
            <FAQ Faqs={faq.faqs} />
          </section>
        )}
      </>
    </PageLayout>
  );
};

export default Trainers;

export const query = graphql`
  query trainerPageQuery {
    strapiPersonalTrainerPage {
      id
      PageDescription
      title
      slug
      seo {
        metaDescription
        metaTitle
      }
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      faq {
        tagline
        title
        faqs {
          title
          answer
        }
      }
      personalTrainer: personal_trainers {
        qualification
        name
        slug
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 520, height: 520)
            }
          }
        }
      }
    }
  }
`;
